import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "components/layout";
import SEO from "components/seo";
import RichText from "components/rich-text";
import Box from "components/box";
import Grid from "components/grid";
import ProjectCard from "components/project-card";

import "./home.css";

const description = `
[I'm](https://merveilles.town/@mario_afk) a Mexican [game](/projects?category=games) and [web](/projects?category=web) developer [NOW](/now).

I have been making [small games](https://afk-mario.itch.io/) sice ~2014. I like to talk and write about games at [Detrás Del Pixel](https://detrasdelpixel.com) and run with some friends a Spanish speaking [community](https://indi-es.com/) for game developers. 

I also work as a [front end developer](https://scalero.io/) and do [small](https://factura.now.sh/) [web](https://addons.mozilla.org/en-US/firefox/addon/busca/) [experiments](https://www.horchata.club/) when I have a chance.

`;

const IndexPage = ({ data }) => {
  const projects = data.ellugar.allProjects.edges.filter(
    ({ node }) => node.publish
  );

  return (
    <Layout>
      <SEO title="Home" />
      <div className="wrapper">
        <Box className="home-box">
          <img
            className="avatar"
            src="https://www.gravatar.com/avatar/ca9850d5f37fdc233a6dcd03ad211886?s=1024"
            alt="avatar"
            title="avatar"
          />

          <div>
            <RichText text={description} />
          </div>
        </Box>
        <Grid className="home-project-grid">
          {projects.map(({ node }) => {
            const { id, slug, image, name } = node;
            return (
              <ProjectCard
                key={id}
                id={id}
                name={name}
                image={image}
                slug={slug}
              />
            );
          })}
        </Grid>
      </div>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    ellugar: PropTypes.shape({
      allProjects: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
              slug: PropTypes.string,
              name: PropTypes.string,
              image: PropTypes.string,
              publish: PropTypes.bool,
            }),
          })
        ),
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query {
    ellugar {
      allProjects {
        edges {
          node {
            slug
            id
            name
            image
            publish
          }
        }
      }
    }
  }
`;

export default IndexPage;
